/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import BlueprintConfig from "../../BlueprintConfig";
import {
  Show,
  SimpleShowLayout,
  Title,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  useRecordContext,
  RichTextField,
  List,
  ResourceContextProvider,
} from "react-admin";
import { SubResourceShow } from "../../GenericComponents/SubResource/components";
import { Card } from "@mui/material";
import moment from "moment";

import {
  AssignInsuranceClassesPrompt,
  CreateSelectionsForSelectionWindowPrompt,
  CreateBenefitOfferingsPrompt,
  CreatePlanOfferingsPrompt,
  WaitingForEnrollmentCreationPrompt,
  WaitingForSelectionWindowProcessingPrompt,
  ResolveNoyoMemberMismatches,
  ResolveNoyoDependentMismatches,
  ResolveNoyoGroupPlanMismatches,
  MapPlansToZywavePrompt,
  SelectPlansToQuotePrompt,
  EligibleMedicalCarriersForQuotingPrompt,
  MapPreviousPlanOfferingsAndContributionsPrompt,
} from "./components/prompts";
import { WorkflowActionButton } from "./components";

const WorkflowInteractions = function WorkflowInteractions() {
  const workflowRun = useRecordContext();
  const { paused, terminal, transitions } = workflowRun;
  const canPause = !paused && !terminal;
  const canUnpause = paused;
  const canAdvance = !terminal;

  return (
    <span>
      <h4>Actions</h4>
      {canPause && (
        <WorkflowActionButton
          id={workflowRun.id}
          action="pause"
          successMsg={(id) => `Run ${id} paused.`}
        />
      )}
      {canUnpause && (
        <WorkflowActionButton
          id={workflowRun.id}
          action="unpause"
          successMsg={(id) => `Run ${id} unpaused.`}
        />
      )}
      {canAdvance && (
        <WorkflowActionButton
          id={workflowRun.id}
          action="advance"
          successMsg={(id) => `Run ${id} advanced.`}
        />
      )}
      {transitions.map((transition) => (
        <WorkflowActionButton
          id={workflowRun.id}
          key={transition}
          action="event"
          subAction={transition}
          successMsg={(id) => `Event ${transition} for run ${id} triggered.`}
        />
      ))}
    </span>
  );
};

const WorkflowEventList = function WorkflowEventList() {
  const { id: workflowRunId } = useRecordContext();

  return (
    <span>
      <h4>Event History</h4>
      <List
        resource="workflow_events"
        filter={{ workflow_run_id: workflowRunId }}
        sort={{ field: "event_timestamp", order: "DESC" }}
        disableSyncWithLocation={true}
      >
        <Datagrid rowClick={false}>
          <TextField label="Timestamp" source="event_timestamp" />
          <TextField label="Result" source="advancement_result" />
          <TextField label="Starting State" source="initial_state" />
          <TextField label="End State" source="end_state" />
          <TextField label="Error message" source="data.error_message" />
          <TextField
            style={{ whiteSpace: "pre-wrap" }}
            label="Details"
            source="data.details"
          />
        </Datagrid>
      </List>
    </span>
  );
};
const WorkflowAdminTitle = function WorkflowAdminTitle() {
  const { id, name } = useRecordContext();
  return <Title title={`${name} run ID: ${id}`} />;
};

const calculateDelay = function calculateDelay(record) {
  const { advance_after: delayTimestamp } = record;
  const delayString = moment(delayTimestamp).fromNow();

  return delayString;
};

const WorkflowPrompt = function WorkflowPrompt({ prompt, promptType }) {
  if (!prompt) return "";
  switch (promptType) {
    case "quote_request_error_prompt":
      return <div>Retry as soon as zywave is back up</div>;
    case "no_plans_found_in_quote_prompt":
      return <MapPlansToZywavePrompt data={prompt} />;
    case "map_plans_to_zywave_prompt":
      return <MapPlansToZywavePrompt data={prompt} />;
    case "select_plans_to_quote_prompt":
      return <SelectPlansToQuotePrompt data={prompt} />;
    case "map_previous_plan_offerings_and_contributions_prompt":
      return <MapPreviousPlanOfferingsAndContributionsPrompt data={prompt} />;
    case "assign_insurance_classes_prompt":
      return <AssignInsuranceClassesPrompt data={prompt} />;
    case "create_benefit_offerings_prompt":
      return <CreateBenefitOfferingsPrompt data={prompt} />;
    case "create_plan_offerings_prompt":
      return <CreatePlanOfferingsPrompt data={prompt} />;
    case "create_member_selections_prompt":
      return <CreateSelectionsForSelectionWindowPrompt data={prompt} />;
    case "waiting_for_member_selection_completion_prompt":
      return <CreateSelectionsForSelectionWindowPrompt data={prompt} />;
    case "autogenerating_and_waiting_for_member_selection_completion_prompt":
      return <CreateSelectionsForSelectionWindowPrompt data={prompt} />;
    case "waiting_for_enrollment_creation_prompt":
      return <WaitingForEnrollmentCreationPrompt data={prompt} />;

    case "waiting_for_renewal_selection_window_processing_prompt":
      return <WaitingForSelectionWindowProcessingPrompt data={prompt} />;
    case "unable_to_map_all_members_to_noyo_prompt":
      return <ResolveNoyoMemberMismatches data={prompt} />;
    case "unable_to_map_all_dependents_to_noyo_prompt":
      return <ResolveNoyoDependentMismatches data={prompt} />;
    case "unable_to_map_all_renewals_plans_to_noyo_prompt":
      return <ResolveNoyoGroupPlanMismatches data={prompt} />;
    case "unable_to_map_all_group_plans_to_noyo_prompt":
      return <ResolveNoyoGroupPlanMismatches data={prompt} />;
    case "not_eligible_for_quoting_prompt":
      return <EligibleMedicalCarriersForQuotingPrompt data={prompt} />;
    case "check_census_eligibility_prompt":
      return <EligibleMedicalCarriersForQuotingPrompt data={prompt} />;
    case "complete_prompt":
      return <EligibleMedicalCarriersForQuotingPrompt data={prompt} />;
    case "complete_not_eligible_prompt":
      return <EligibleMedicalCarriersForQuotingPrompt data={prompt} />;
    // Other prompt components may be added here.
    case "text":
      return (
        <RichTextField label="Prompt" source="prompt" sx={{ fontSize: 10 }} />
      );
    default:
      throw new Error(`Invalid prompt type ${promptType}`);
  }
};

const Prompt = function Prompt() {
  const { prompt, prompt_type: promptType } = useRecordContext();
  return <WorkflowPrompt prompt={prompt} promptType={promptType} />;
};

const getConfigForResource = function getConfigForResource(resource) {
  return Object.values(BlueprintConfig).find(
    (config) => config.backend_resource === resource
  );
};

const DynamicOwnerShow = function DynamicOwnerShow() {
  const workflowRun = useRecordContext();
  if (!workflowRun) return null;

  const {
    owner_id: ownerId,
    owner_resource: ownerResource,
    owner_type: ownerType,
  } = workflowRun;
  const config = getConfigForResource(ownerResource);
  const ShowComponent = SubResourceShow(config);
  return (
    <ResourceContextProvider value={ownerResource}>
      <Card sx={{ paddingLeft: "10px", marginLeft: "10px" }}>
        <h4>{ownerType} Details</h4>
        <ShowComponent id={ownerId} />
      </Card>
    </ResourceContextProvider>
  );
};

const WorkflowAdminTool = function WorkflowAdminTool() {
  return (
    <Show title=" " aside={<DynamicOwnerShow />}>
      <SimpleShowLayout>
        <WorkflowAdminTitle />
        <TextField label="Workflow Run ID" source="id" />
        <TextField label="Workflow Name" source="name" />
        <TextField label="Owner Type" source="owner_type" />
        <TextField label="Current State" source="state" />
        <FunctionField label="Delayed until" render={calculateDelay} />

        <BooleanField label="Complete?" source="complete" />
        <BooleanField label="Terminal?" source="terminal" />
        <BooleanField label="Paused?" source="paused" />
        <BooleanField label="Healthy?" source="healthy" />
        <hr />
        <Prompt />
        <hr />
        <WorkflowInteractions />
        <hr />
        <WorkflowEventList />
      </SimpleShowLayout>
    </Show>
  );
};

export default WorkflowAdminTool;
