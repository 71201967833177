import { React } from "react";

const EligibleMedicalCarriersForQuotingPrompt =
  function EligibleMedicalCarriersForQuotingPrompt({ data }) {
    const {
      quote_request_includes_medical,
      eligibility_summary,
      census_has_rows,
    } = data;

    let eligibility_result = "";

    const eligibilitySummaryDisplay = () => {
      if (eligibility_summary.length === 0) {
        return "No eligible carriers are configured for this state.";
      }

      eligibility_summary.forEach(
        (element) =>
          (eligibility_result += element.carrier + element.details + "\n")
      );

      return (
        <div>
          <p style={{ whiteSpace: "pre-wrap" }}>{eligibility_result}</p>
        </div>
      );
    };

    if (census_has_rows === false) {
      return (
        <div>
          <p>
            This quote request does not have an uploaded census and will bypass
            checking for census eligibility.
          </p>
        </div>
      );
    }

    if (quote_request_includes_medical === false) {
      return (
        <div>
          <p>
            This quote request does not include medical and will bypass checking
            for census eligibility.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h4>Carrier Eligibility Results</h4>
        {eligibilitySummaryDisplay()}
      </div>
    );
  };

export default EligibleMedicalCarriersForQuotingPrompt;
