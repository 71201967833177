import React from "react";
import { Button } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const PlanCreateButtonComponent = ({ label, source }) => {
  const openPlanCreateDashboardToolInNewTab = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/plans/create`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Button
        label="CLICK HERE TO AUTOMATICALLY CREATE MEDICAL PLANS"
        onClick={openPlanCreateDashboardToolInNewTab}
      />
    </div>
  );
};

export const PlanCreateButton = function PlanCreateButton(props) {
  return (
    <PlanCreateButtonComponent key="PlanCreateButtonComponent" {...props} />
  );
};
